import React from 'react';

const getValue = (item) => {
    if (Array.isArray(item)) {
        return item.map(sub => getValue(sub)).join(', ');
    } else if (item && typeof item === 'object') {
        if (item.value && typeof item.value === 'object') {
            if(item.value.firstName) return getFullName(item)
            else return  item.value.name || JSON.stringify(item.value);
        }
        return item.value || JSON.stringify(item);
    } else if (typeof item === 'string' || typeof item === 'number') {
        return item;
    }
    return '';
};

const getFullName = (item) =>{
    if(item && item.value){
        return item.value.firstName + " " + item.value.lastName
    }
    else return "-"
}

const renderRow = (title, value) => (
    <tr>
        <td className="field-title">{title}</td>
        <td className="field-value">{value}</td>
    </tr>
);

const CategoryCard = ({ category }) => {
    return (
        <div className="data-card">
            <table className="data-table">
                <tbody>
                    {renderRow('Name', getValue(category.name))}
                    {renderRow('Description', getValue(category.desc))}
                    {renderRow('Unique Properties', getValue(category.uniqueProperties))}
                    {renderRow('Bio', getValue(category.bio))}
                    {renderRow('Author', getValue(category.ipAuthor))}
                    {renderRow('Owner', getValue(category.owner))}
                    {renderRow('Tags', getValue(category.tags))}
                    {category.group && renderRow('Category', getValue(category.group))}
                    {category.subGroup && renderRow('Subcategories', getValue(category.subGroup))}
                    {renderRow('Usage', getValue(category.usage))}
                    {renderRow('Factory', getValue(category.factoryName))}
                    {renderRow('Factory URL', getValue(category.urlOnFactorySite))}
                    {category.featuresArray && (
                        <>
                            <tr>
                                <td colSpan="2"><strong>Features</strong></td>
                            </tr>
                            {category.featuresArray.map((feature, index) => (
                                renderRow(feature.feature?.value?.name || `Feature ${index + 1}`, getValue(feature.featureValue))
                            ))}
                        </>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default CategoryCard;



// const CategoryCard = ({ category }) => {
//     return (
//         <div className="data-card">
//             <table className="data-table">
//                 <tbody>
//                     <tr>
//                         <td className="field-title">Name</td>
//                         <td className="field-value">{category.name}</td>
//                     </tr>
//                     <tr>
//                         <td className="field-title">Description</td>
//                         <td className="field-value">{category.desc}</td>
//                     </tr>
//                     <tr>
//                         <td className="field-title">Unique Properties</td>
//                         <td className="field-value">{category.uniqueProperties}</td>
//                     </tr>
//                     <tr>
//                         <td className="field-title">Bio</td>
//                         <td className="field-value">{category.bio}</td>
//                     </tr>
//                     <tr>
//                         <td className="field-title">Author</td>
//                         <td className="field-value">{category.ipAuthor}</td>
//                     </tr>
//                     <tr>
//                         <td className="field-title">Owner</td>
//                         <td className="field-value">{category.owner}</td>
//                     </tr>
//                     <tr>
//                         <td className="field-title">Tags</td>
//                         <td className="field-value">{category.tags}</td>
//                     </tr>
//                     {category.group && (
//                         <tr>
//                             <td className="field-title">Category</td>
//                             <td className="field-value">{category.group.value.name}</td>
//                         </tr>
//                     )}
//                     {category.subGroup && (
//                         <tr>
//                             <td className="field-title">Subcategories</td>
//                             <td className="field-value">
//                                 {
//                                     (() => {
//                                         if (Array.isArray(category.subGroup)) {
//                                             return category.subGroup.map(sub => sub.value.name).join(', ');
//                                         } else if (category.subGroup && category.subGroup.value) {
//                                             return category.subGroup.value.name;
//                                         } else {
//                                             return ''; // or handle the case when category.subGroup is not properly defined
//                                         }
//                                     })()
//                                 }
//                             </td>
//                         </tr>
//                     )}
//                     <tr>
//                         <td className="field-title">Usage</td>
//                         <td className="field-value">{category.usage}</td>
//                     </tr>
//                     <tr>
//                         <td className="field-title">Factory</td>
//                         <td className="field-value">{category.factoryName}</td>
//                     </tr>
//                     <tr>
//                         <td className="field-title">Factory URL</td>
//                         <td className="field-value">{category.urlOnFactorySite}</td>
//                     </tr>
//                     <tr>
//                         <td className="field-title">Tags</td>
//                         <td className="field-value">{category.tags}</td>
//                     </tr>
//                     <tr>
//                         <td colSpan="2"><strong>Features</strong></td>
//                     </tr>
//                     {category.featuresArray.map((feature, index) => (
//                         <tr key={index}>
//                             <td className="field-title">{feature.feature?.value.name}</td>
//                             <td className="field-value">{feature.featureValue}</td>
//                         </tr>
//                     ))}
//                 </tbody>
//             </table>
//         </div>
//     );
// };

// export default CategoryCard;
