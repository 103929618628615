import './App.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Spinner from './Spinner';
import CategoryCard from './CategoryCard';
import ProductCard from './ProductCard';

const API_KEY = process.env.REACT_APP_API_KEY;
const API_URL = process.env.REACT_APP_API_URL
const App = () => {
  const [product, setProduct] = useState(null);
  const [category, setCategory] = useState(null);
  const [data, setData] = useState(null);
  const [collection, setCollection] = useState('');
  const [loading, setLoading] = useState(true);
  const [hideImg, setHideImg] = useState(false);
  const [error, setError] = useState(null);

  // Function to get query parameters
  const getQueryParam = (param) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  };

  useEffect(() => {
    const itemId = getQueryParam('itemId');
    const collection = getQueryParam('collection');
    const hideImgParam = getQueryParam('hideImg');
    const locale = getQueryParam('locale') || 'en'; // english by default

    if (!itemId || !collection) {
      setError('Missing required query parameters.');
      setLoading(false);
      return;
    }
    if (hideImgParam) setHideImg(true)
    setCollection(collection);

    const API = `${API_URL}/${collection}/${itemId}?locale=${locale}&draft=true&depth=1`;
    const fetchData = async () => {
      try {
        const response = await axios.get(API, {
          headers: {
            'Authorization': `users API-Key ${API_KEY}`
          }
        });
        setData(response.data)
        setLoading(false);
      } catch (error) {
        console.log("error: ", error)
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="data-overview">
      {collection.includes('Products') && data && <ProductCard product={data} hideImg={hideImg} />}
      {collection.includes('Categories') && data && <CategoryCard category={data} />}
    </div>

    // <div className="product-overview">
    //   {product && <ProductCard product={product} hideImg={hideImg} />}
    // </div>
  );
};


export default App;
