const ProductCard = ({ product, hideImg }) => {
	const placeholderImage = './image-placeholder.png'; // Path to your placeholder image

	return (
		<div className="data-card">
			<table className="data-table">
				<tbody>
					{!hideImg && (
						<tr>
							<td colSpan="2">
								<img
									src={product.designImageSlider[0]?.designImage.url || placeholderImage}
									alt={product.name}
									className="data-image"
								/>
							</td>
						</tr>
					)}
					<tr>
						<td className="field-title">Name</td>
						<td className="field-value">{product.name}</td>
					</tr>
					{product.category && (
						<tr>
							<td className="field-title">Collection</td>
							<td className="field-value">{product.category.value.name}</td>
						</tr>
					)}
					<tr>
						<td className="field-title">Product ID</td>
						<td className="field-value">{product.externalId}</td>
					</tr>
					{product.subGroup && (
						<tr>
							<td className="field-title">Subcategory</td>
							<td className="field-value">{product.subGroup.value.name}</td>
						</tr>
					)}
					<tr>
						<td className="field-title">Unique Selling Points</td>
						<td className="field-value">{product.uniqueProperties}</td>
					</tr>
					<tr>
						<td className="field-title">Tags</td>
						<td className="field-value">{product.tags}</td>
					</tr>
					<tr>
						<td colSpan="2"><strong>Features</strong></td>
					</tr>
					{product.featuresArray.map((feature, index) => (
						<tr key={index}>
							<td className="field-title">{feature.feature?.value.name}</td>
							<td className="field-value">{feature.featureValue}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default ProductCard;
